@import '~config/variables.scss';

.title {
  font-weight: 600;
  font-size: 22px;
  text-align: center;
}

.subTitle {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.modal {
  max-width: 370px;
}

.textCenter{
  text-align: center;
}

.helper{
  color: $light-gray;
}

.alreadyRegistered {
  margin: 10px 0;
}

.registeredDonors {
  font-weight: 600;
}