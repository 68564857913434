.head {
  font-size: 8px;
  line-height: 1;
}

.title {
  h1 {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }
}

.icon {
  max-width: 50px;
}

.headColumns {
  display: grid;
  grid-template-columns: 50px 250px 130px 205px;
  justify-content: space-between;
  align-items: end;
}

.nameColumn {
  line-height: 1;
  h2 {
    font-size: 12px;
    font-weight: bold;
  }

  p {
    font-size: 8px;
    margin: 0;

    &:last-child {
      padding-left: 5px;
    }
  }
}

.addresses {
  font-size: 7px;
  line-height: 1;
  display: flex;
  gap: 20px;
  margin-top: 5px;
}

.contacts {
  line-height: 1;
  font-size: 7px;
  .contactsItem {
    display: grid;
    grid-template-columns: 30px 1fr;
  }

  p {
    margin: 0;
  }
}

.labUseOnly {
  border: 2px solid #000;
  background-color: rgb(234, 234, 234);

  p {
    margin: 0;
  }

  div {
    padding: 3px;
  }

  div:first-child {
    border-bottom: 1px solid #000;
    font-style: italic;
    padding-bottom: 10px;
  }
}

.note {
  margin: 0;
  text-align: center;
}
