@import '~config/variables.scss';

.printIcon {
  transition: all 0.3s;
  font-size: 20px;
  line-height: 32px;
  &:hover {
    color: $light-blue;
  }
  &.disabled {
    color: $black;
    cursor: not-allowed;
    opacity: 0.5;
  }
}
