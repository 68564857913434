.container {
  display: block;
  height: 20px;
  margin: 0 auto;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
}

.containerOn {
  opacity: 1;
  &:hover {
    opacity: 0.3;
  }
}

.containerOff {
  opacity: 0;
  &:hover {
    opacity: 0.5;
  }
}
