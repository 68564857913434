.title {
    width: 100%;
  h3 {
    font-weight: bold;
    text-align: center;
    font-size: 14px;
    margin: 0;
    line-height: 1;
  }
}
