.container {
  display: block;
  height: 20px;
  margin: 0 auto;
  transition: 0.3s;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
}

.containerOn {
  opacity: 1;
}

.containerOff {
  opacity: 0;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

@media (pointer: fine) {
  .containerOn {
    &:hover {
      opacity: 0.3;
    }
  }
  
  .containerOff {
    &:hover {
      opacity: 0.5;
    }
  }
  
  .disabled {
    &:hover {
      opacity: 0.3;
    }
  }
}