.receipt {
  border: 1px solid #000;
  margin-top: 10px;
  padding: 10px;

  .labUseOnly {
    font-size: 12px;
    font-weight: bold;
    font-style: italic;
  }

  .receiptAdditionalInfo {
    display: grid;
    grid-template-columns: 2fr repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);

    p {
        margin: 0;
    }
  }

  .receiptAdditionalInfoInput {
    grid-column: span 2;
  }
}
