.content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.formContainer {
  width: 100%;
}

.title {
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 40px;
}
