.row {
  @media(hover: none), (max-width: 600px) {
    :global(.linkIcon) {
      opacity: 1;
    }
  }

  :global(.ant-table-cell-row-hover) {
    :global(.linkIcon) {
      opacity: 1;
    }
  }
}