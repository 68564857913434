.groupCard {
  :global(.ant-card-head) {
    padding: 0 10px;
  }
  :global(.ant-card-body) {
    padding: 10px;
  }
  &.empty {
    :global(.ant-card-body) {
      padding: 0;
    }
  }
}

.disabledIcon {
  opacity: 0.3;
  &:global(.anticon[tabindex]) {
    cursor: not-allowed;
  }
}
