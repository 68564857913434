.pageBody {
  padding: 15px 10px;
  letter-spacing: 0;
  position: relative;
  min-height: 100%;
}

.page {
  width: 8.5in;
  height: 11in;
  margin: 0 auto;
}
