.head {
  font-size: 8px;

  .logo {
    max-width: 85%;
  }
  h3 {
    font-weight: bold;
    text-align: center;
    font-size: 16px;
    margin: 0;
  }

  a {
    color: #000;
  }

  .addressColumns {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 0 10px;
  }

  .accessionBox {
    width: 100%;
    height: 100%;
    border: 1px solid #000;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
      font-size: 12px;
      font-weight: bold;
    }
  }

  .input {
    input {
      max-width: 80px;
    }
  }

  .accessionBoxInputs {
    height: fit-content;

    label {
      font-size: 11px;
      line-height: 0;
    }

    input {
      font-size: 14px;
    }

    p {
      margin: 0;
      padding: 0;
      text-align: center;
      font-style: italic;
    }
  }
}
