.copyrightSection {
  margin-top: 15px;
  font-family: Lucida Sans, serif;
  font-size: 6pt;
  width: 100%;
}

.logo {
  width: 100px;
  height: 45px;
  margin-top: -20px;
}
