
.radioButtonWrapper {
  label {
    display: block;
    position: relative;
  }

  label input {
    display: none;
  }

  label span {
    border: 0.5px solid #000;
    width: 8px;
    height: 8px;
    line-height: 1;
    text-align: center;
    border-radius: 100%;
    font-size: 6px;
    padding-left: 2px;
    padding-right: 2px;
  }

  .empty {
    padding: 0 3.5px;
  }
}
