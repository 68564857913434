@import '~config/variables.scss';

.imagesWrapper {
  margin-top: 15px;
  @media (max-width: $md) {
    justify-content: space-evenly;
  }
}

:global(.ant-upload-select ){
  width: 100%;
}

.imageContainer {
  width: 300px;
  height: 300px;
  :global(.ant-image) {
    width: 100%;
    height: 100%;
  }
  @media (max-width: $sm) {
    width: 200px;
    height: 200px;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imageCard {
  width: 300px;
  @media (max-width: $sm) {
    width: 200px;
  }
}

.edit{
  max-width: 150px;
  width: 100%;
  padding: 4px 0;
  transition: 0.3s color;;

  &:hover{
    color: $light-blue;
  }
}
