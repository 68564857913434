.divider {
  &:global(.ant-divider-plain.ant-divider-with-text) {
    &::after,
    &::before {
      top: 0;
    }
    margin: 0;
    font-size: 11px;
  }
}

.container {
  overflow: hidden;
}

.title {
  width: 170px;
}

.location {
  width: 120px;
}

.teamTitle,
.donorName {
  width: 140px;
  color: inherit;
}
