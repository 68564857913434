@import '~config/variables.scss';

.submitButton {
  margin-top: 15px;
  width: 100%;
  border-radius: 25px;
  background-color: $red;
  font-size: 26px;
  line-height: 26px;
  height: 50px;
  font-weight: 700;
  border-color: $red;
  box-shadow: 0 0 0 black;
  &:hover {
    background-color: $red;
    border-color: $red;
    box-shadow: 0 0 18px -10px black;
  }
  &:focus,
  &:active {
    background-color: $red;
    border-color: $red;
    box-shadow: inset 0 0 10px -8px black;
  }
}
