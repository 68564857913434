@import '~config/variables.scss';

.linkIcon {
  font-size: 15px;
  transition: all 0.3s;

  &:hover {
    opacity: 0.8;
  }
}
