.container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  height: 80px; 
}

.content {
  display: flex;
  align-items: space-between;
  justify-content: space-between;
  gap: 15px;
}

.infoContainer {
  display: flex;
  align-items: space-between;
  gap: 10px;
}

.forLabs {
  border: 1px solid black;
  padding: 5px 20px;
  height: 100%;
  font-size: 11px;
  height: 80px; 
}

.collectionDate {
  text-wrap: nowrap;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  padding-bottom: 0;
  font-size: 11px;
  height: fit-content !important;
  text-align: center;
}

.datePart {
  margin: 0 0.5px;
}

.slash {
  margin: 0 0.5px;
}

.logoContainer {
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 0;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 110px;
  height: 30px;
  background-image: url('https://www.idexx.com/static/css/idexxcom/logo-white.1511d2e5.29d854ac0fa1.svg');
  background-size: contain;
  background-repeat: no-repeat;
  filter: invert(1) grayscale(100%) contrast(100%) brightness(0) sepia(100%);
}

.logoText, .logoNumber, .logo {
  font-size: 12px;
  margin: 0;
  padding: 0;
}
