@import '~config/variables.scss';

.container {
  width: 100%;
  height: 100vh;
  padding-top: 10%;
}

.title {
  font-size: 24px;
  font-weight: 600;
}

.errorMessage {
  font-size: 16px;
  color: $red;
}

.errorStack {
  padding: 0;
  &:global(.ant-collapse) {
    :global(.ant-collapse-header) {
      padding: 0;
    }
  }
}
