.animalIdentification {
  font-size: 8px;
  line-height: 1;

  label {
    font-size: 8px;
  }

  p {
    margin: 0;
  }

  .openedByInput {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    align-items: flex-start;

    input {
      max-height: 10px;
      font-size: 10px;
      padding: 0;
      transform: translateY(-10px);
    }
  }

  .productDataWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .productData {
      height: 12px;

      input {
        height: 12px;
      }
    }
  }

  .checkboxTable {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4px;

    :global(.checkbox) {
      input {
        width: 10px;
        height: 10px;
        background-color: rgba(0, 0, 0, 0);
      }
    }

    :global(.input) {
      width: 25px;
      height: 10px;
    }
  }
}

.checkboxLeftGroup {
  border-right: 1px solid #000;
}

.productDataSection {
  border: 1px solid #000;
  padding: 0 5px;
  background: #eaeaea;
  input {
    max-width: 130px;
  }
}

.checkboxGroup {
  padding: 5px 0px;
}

.table {
  font-size: 8px;

  table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid black;
  }
  th,
  td {
    border: 1px solid black;
    text-align: left;
    padding: 0;
    text-align: center;
  }
  th {
    text-align: center;
  }

  td {
    height: 25px;
  }

  .itemNumber {
    font-weight: bold;
    font-size: 12px;
  }

  .tableAnimalIdentification {
    font-weight: 900;
    text-align: center;
    margin: 0;
    font-size: 12px;
  }

  .noBottomBorder {
    border-bottom: none;
    border-top: none;
  }

  .specimenType {
    font-size: 16px;
    text-align: left;
    padding: 4px;
    font-weight: 500;
  }
}

.comments {
  padding: 0 5px;
  border: 1px solid #000;
  display: flex;
  align-items: center;
  p {
    font-size: 12px;
  }
}

.commentCheckbox {
  display: flex;
  align-items: center;
  div {
    min-width: 15px;
    height: 15px;
    border: 1px solid #000;
  }
  p {
    text-align: center;
    font-size: 10px;
  }
}
