@import '~config/variables.scss';
.notificationIcon {
  font-size: 20px;
  cursor: pointer;
  color: $light-blue;
  transition: all 0.3s;

  &:hover {
    opacity: 0.5;
  }
}

.badge{
  margin-right: 40px;
}
