@import '~config/variables.scss';

.tag {
  max-width: 100%;
  margin-right: 3px;
  padding: 0 3px;
  color: $black;
  :global(.ant-tag-close-icon) {
    color: $black;
  }
}

.tagContent {
  float: left;
  max-width: 93%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
