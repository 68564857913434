@import '~config/variables.scss';

.notes {
  max-width: 50%;
}

.card {
  padding: 4px;
}

.commentArea {
  resize: vertical;
  max-height: 150px;
}

.notesForm {
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 5px;
}

.editNoteBtns {
  display: flex;
}

.noteInputWrapper {
  margin-bottom: 30px;
}
.noteInputWrapperButtons {
  display: flex;
  justify-content: end;
  gap: 20px;
}

.notesWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.noteEdit {
  display: flex;
  justify-content: end;
}

.noteHeader {
  display: flex;
  flex-direction: column;
}

.editDate {
  display: flex;
  justify-content: end;
}

.noteInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.noteDate {
  opacity: 0.4;
  font-size: 12px;
  margin: 0;
}

.noteAuthor {
  font-size: 16px;
  font-weight: bold;
  color: $light-gray;
  margin: 0;
  margin-bottom: 0;
}

.pin {
  display: flex;
  justify-content: end;
  margin-bottom: 5px;

  span {
    height: 18px;
    width: 18px;
    opacity: 0.5;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
