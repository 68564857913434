@import '~config/variables.scss';

.disabled {
  cursor: not-allowed;
  :global(.ant-checkbox-input) {
    cursor: not-allowed;
    :global(.ant-checkbox-inner) {
      border: inherit;
    }
  }
  :global(.ant-checkbox) {
    :global(.ant-checkbox-inner) {
      border-color: $dirty-white;
    }
  }
  opacity: 0.5;
}
