.tabsContainer {
  :global {
    .ant-tabs-nav {
      margin: 0;
    }

  .ant-tabs-tabpane {
    background: white;
    padding: 10px;
  }
  }
}

