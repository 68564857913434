.radioButton {
  height: 8px;
  width: 8px;
  margin: 0 5px;
}

.radioButtonLabel {
  font-family: Roboto, serif;
  font-size: 10px;
  font-weight: bold;
}
