.fieldValue {
  border: 1px solid black;
  padding: 0 2px;
  width: fit-content;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fieldValues {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20px, 1fr));
  gap: 2px;
  padding-bottom: 5px;
}

.veterinarian {
  height: 150px;
  border: 1px solid black;
  padding: 10px;
  margin-top: 5px;
}

.infoElement {
  padding-top: 5px !important;
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.emptyBox {
  height: 50px;
  border: 1px solid black;
  padding: 5px;
}

.table {
  border-collapse: collapse;
}

.cell {
  padding-top: 4pt;
  padding-left: 2pt;
  text-align: left;
}

.cellContent {
  text-align: left;
  vertical-align: text-top;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 4px !important;
  gap: 2px;
}

.checkboxContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.listItem {
  padding-top: 2pt;
  padding-left: 18pt;
  line-height: 9pt;
  text-align: left;
}

.list {
  list-style: none;
}
