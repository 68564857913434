@import '~config/variables.scss';

.datePicker {
  width: 100%;

  :global {
    .ant-picker-input > input[disabled] {
      color: $black;
    }

    .ant-picker-input input {
      &::placeholder {
        color: $transparent-black;
      }
    }
  }
}
