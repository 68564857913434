.leaveCommentButton {
  margin-bottom: 24px;
}

.flatFormItem {
  flex-direction: row;
}

.textArea {
  resize: vertical;
  max-height: 150px;
}