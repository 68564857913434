.comment {
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.abbreviation {
  border-bottom: none !important;
  text-decoration: none !important;
}
