.formItem {
  flex-direction: row;
  align-items: center;
  :global(.ant-form-item-label) {
    margin: 0;
  }
}

.title {
  font-size: 18px;
  font-weight: 600;
  font-size: 22px;
  text-align: center;
  margin-bottom: 10px;
}

.subtitle {
  text-align: center;
  margin: -10px 0 10px 0;
}
