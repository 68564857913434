.notesField {
  resize: vertical;
  max-height: 280px;
}

.formItem {
  flex-direction: row;
  :global(.ant-form-item-label) {
    margin: 0;
  }
}
