.comment {
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.abbreviation {
  border-bottom: none !important;
  text-decoration: none !important;
}

.date {
  font-style: italic;
}

.columnWrapper {
  text-align: start;
  width: 310px;
}

.noteActionButtons {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  margin-bottom: 5px;
}
