.section {
  margin-bottom: 10px;

  .title {
    font-family: Lucida Sans, serif;
    font-size: 10pt;
    font-weight: bold;
    color: #396FB6;
    background-color: #F0F1F9;
    padding-left: 5px;
  }

  .itemTitle {
    font-family: Calibri, serif;
    font-size: 9pt;
    font-weight: bolder;
  }

  .itemTitleWrapper {
    margin-right: 5px;
  }

  .bottomLine {
    padding: 3px;
    border-bottom: 1px dotted grey;
    box-sizing: border-box;
  }

  .itemDetails {
    font-family: Tahoma, serif;
    font-size: 8pt;
    margin-left: 20px;
    margin-top: -5px;
  }
}

