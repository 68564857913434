@import '~config/variables.scss';

.printIcon {
  font-size: 25px;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    color: $light-blue;
  }
}
