@import '~config/variables.scss';

.inputNumber {
  width: 100%;
}

:global(.ant-form label) {
  height: auto;
}

.textArea {
  resize: vertical;
  max-height: 150px;
}