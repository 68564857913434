.modal {
  max-width: 900px;
}

.categories {
  max-width: none;
}

.calendarIcon {
  font-size: 40px;
}

.subtitle {
  font-weight: 600;
}

.formItem {
  :global(label) {
    font-weight: 600;
  }
}

.errorAlert {
  margin-top: 20px;
}
