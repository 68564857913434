.input {
  border: 0;
  outline: 0;
  border-bottom: 1px solid #9e9fa4;
  height: 20px;
  line-height: 1;
  box-sizing: border-box;
  font-size: 18px;
  background-color: rgba(0, 0, 0, 0);
}

.inputWrapper {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 2px;
  line-height: 1;
  font-size: 13px;

  input {
    flex: 1 1 auto;
    width: 100px;
  }
}

.fill {
  input {
    width: 100%;
  }
}
