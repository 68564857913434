.title {
  width: 100%;
  h3 {
    font-weight: bold;
    text-align: center;
    font-size: 16px;
    margin: 0;
  }
}

.formWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 2fr 0.7fr;
  gap: 5px;
  width: 100%;

  .formBox {
    border: 1px solid #000;
    width: 100%;
    height: 100%;
    padding: 5px;
    max-width: 380px;
  }
}

.herdInformation {
  input {
    max-width: 45%;
  }
}
