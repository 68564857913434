.title {
  font-family: Calibri, serif;
  font-size: 9.5pt;
  font-weight: bolder;
}

.titleWrapper {
  margin-right: 5px;
}

.subTitle {
  font-family: Tahoma, serif;
  font-size: 8pt;
  margin-left: 20px;
}

.caption {
  font-family: Tahoma, serif;
  font-size: 7pt;
  margin-left: 5px;
}

.tab {
  border: 1px solid #336BB4;
  padding: 5px;
  margin-right: 5px;
  border-radius: 5%;
}
