.typography {
  &:before {
    content: '-';
    margin: 0 10px 0 0;
  }
  :global(.ant-typography-edit) {
    opacity: 0;
    transition: 0.3s;
  }
  &:hover {
    :global(.ant-typography-edit) {
      opacity: 1;
    }
  }
}

.addRecommendation {
  opacity: 0.3;
  transition: all 0.3s;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  &:global(.ant-typography) {
    margin-bottom: 0;
  }
}
