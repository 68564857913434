.history {
  border: 3px solid #000;
  padding: 0;
  font-size: 10px;
  line-height: 1;

  label {
    font-size: 10px;
  }

  p {
    margin: 0;
  }

  .historyCheckboxes,
  .additionalHistoryInfo {
    position: relative;

    .historyTitle,
    .additionalHistoryTitle {
      position: absolute;
      top: 0;
      left: 0;
      line-height: 1;
      display: flex;
      align-items: center;
      font-size: 12px;

      h4,
      h5 {
        font-weight: 900;
        color: #fff;
        background-color: #000;
        padding: 2px;
        margin: 0;
      }

      p {
        margin: 0;
      }
    }

    .historyCheckboxesGrid {
      display: grid;
      grid-template-columns: repeat(4, 1fr) 1.5fr;
      gap: 8px;
      font-size: 10px;
      padding: 0 5px;

      label {
        font-size: 10px;
      }

      :global(.checkbox) {
        input[type='checkbox'] {
          width: 10px;
          height: 10px;
        }

        input {
          height: 10px;
          width: 100%;
        }
      }

      .firstCheckbox {
        grid-column: span 3;
        width: 100%;
        height: 100%;
      }
    }
  }

  .additionalHistoryInfo {
    border-top: 1px solid #000;

    .healthScreening {
      font-size: 16px;
      font-family: serif;
      margin: 30px 0;
      font-weight: 500;
    }

    .checkHere {
      display: flex;
      align-self: flex-start;
      div {
        min-width: 15px;
        height: 15px;
        border: 1px solid #000;
        margin-top: 5px;
      }
      p {
        font-size: 8px;
        text-align: center;
        line-height: 1;
      }
    }
  }

  .historyInputs {
    padding: 0 5px;
  }

  .accession {
    display: flex;
    align-items: center;
    gap: 6px;

    :global(.checkbox) {
      gap: 0;
    }
  }

  .donorInfo {
    label {
      white-space: nowrap;
    }

    input {
      max-width: 45%;
    }
  }
}
