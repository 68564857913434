@import '~config/variables.scss';

.row {
  :global(.ant-table-cell-row-hover) {
    :global(.userIcon) {
      opacity: 1;
    }
  }
  :global(.userIcon) {
    transition: all 0.3s;
    opacity: 0;
  }
}

.archived {
  background-color: $dirty-white;
}
