@import '~config/variables.scss';

.bundleSection {
  width: 100%;
  max-height: 190px;
  height: 190px;
  padding-top: 10px;
  box-sizing: border-box;
  background-color: #fff;

  .title {
    font-family: Lucida Sans, serif;
    font-size: 10pt;
  }

  .explanation {
    font-family: Lucida Sans, serif;
    font-size: 9.5pt;
    color: $light-black;
  }

  .text{
    font-family: Tahoma, serif;
    font-size: 7pt;
  }

  .bundleTabs{
    margin-top: 15px;
    margin-bottom: 20px;
  }
}


