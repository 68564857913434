@import '~config/variables.scss';

.textArea {
  &::placeholder {
    color: $transparent-black;
  }
  &:disabled {
    color: $black;
  }
}
