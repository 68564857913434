.saveButton {
  text-align: right;
}

.noPhoto {
  background-color: grey;
}

.photo {
  width: 200px;
  height: 200px;
}

.formItem {
  padding: 0;
  margin: 0 0 10px;
}

.selectInCell {
  text-align: left;
}

.input {
  text-align: center;
}

.environmentFormItemWrapper {
  margin-bottom: 10px;
}

.environmentFormItem {
  margin-bottom: 10px;
}

.alert {
  margin-bottom: 16px;
}
