@import '~config/variables.scss';

.passwordInput {
  &:global(.ant-input-affix-wrapper > input.ant-input) {
    &::placeholder {
      color: $transparent-black;
    }
    &:disabled {
      color: $black;
    }
  }
}
