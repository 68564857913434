div.form {
  padding: 10px 10px;
}

.askingFillForm {
  font-size: 11px;
  line-height: 1.5;
  border-bottom: 3px solid #000;
  line-height: 1;

  p {
    margin: 0 0 3px 0;
    word-wrap: break-word;
    word-break: break-all;
  }
}

.boxesTip {
  font-size: 11px;
  text-align: center;
  width: 100%;
  line-height: 1;
  margin-bottom: 5px;
}

.columnTitle {
  line-height: 1;
  font-weight: bold;
  text-align: center;
}

.column {
  padding: 0 3px;

  &:not(:last-child) {
    border-right: 1px solid black;
  }
}

.footer {
  display: flex;
  gap: 4cm;
  font-style: 10px;
  font-style: italic;
  font-family: serif;
}
