@import '~config/variables.scss';

.appointmentOverlay {
  max-width: 200px;
  :global(.ant-dropdown-menu) {
    padding: 5px;
  }
}

.appointment {
  border: 1px solid $dirty-white;
  border-radius: 3px;
  transition: 0.3s;
  &:hover {
    opacity: 0.8;
    border-color: $light-blue;
  }
}

.appointmentMember {
  background-color: attr(color, color);
  font-size: 12px;
  line-height: 12px;
  padding: 3px;
  border-radius: 3px;
  white-space: nowrap;
}
