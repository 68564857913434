.table {
  :global(.ant-table) {
    background-color: transparent;
  }
  :global(tr) {
    :global(td) {
      padding: 5px;
      &:first-child {
        font-weight: 500;
        vertical-align: top;
      }
    }
  }
}
