@import '~config/variables.scss';

.errorMessage {
  color: $pastelRed;
}

.recurringContainer {
  &:global(.ant-collapse-item) {
    :global(.ant-collapse-header) {
      padding: 0;
    }
  }
}
