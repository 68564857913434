@import '~config/variables.scss';

.submitButton {
  width: 100%;
  font-weight: 700;
  padding: 10px;
  height: auto;
  font-size: 15px;
  background-color: $dark-blue;
  border-color: $dark-blue;
  &:hover {
    background-color: $blue;
    border-color: $blue;
  }
}

.cooldown {
  :global(.ant-statistic-content) {
    font-size: 14px;
    color: $gray;
  }
}
