@import '~config/variables.scss';

.radioGroup {
  color: red;
  :global(.ant-radio-disabled) {
    :global(.ant-radio-inner::after) {
      background-color: $half-black;
    }
    :global(+ span) {
      color: $transparent-black;
    }
  }
}
