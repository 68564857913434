.formItem {
  flex-direction: row;
  align-items: center;
  :global(.ant-form-item-label) {
    margin: 0;
  }
}

.locationSelect {
  z-index: 2;
  width: 100%;
  &.editable {
    max-width: 185px;
  }
}

.editLocationsButton {
  z-index: 1;
  max-width: 50px;
  width: 100%;
}

.locationsContainer {
  &:global(.ant-input-group.ant-input-group-compact) {
    display: flex;
  }
}
