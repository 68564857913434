@import '~config/variables.scss';

.input {
  width: 100%;

  ::placeholder {
    color: $transparent-black;
  }

  :disabled {
    color: $black;
  }
}
