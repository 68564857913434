@import '~config/variables.scss';

.locationSelect,
.usersFilter {
  width: 100%;
  min-width: 150px;
  max-width: 300px;
}

.printLabel {
  font-size: 32px;
  line-height: 32px;
  height: 32px;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    color: $light-blue;
  }
}

.yearTitle {
  font-weight: 700;
  font-size: 21px;
}
