@import '~config/variables.scss';

.headerLeft {
  display: flex;
  flex-direction: column;
  line-height: 1;
  p,
  h2 {
    padding: 0;
    margin: 0;
  }
}

.licenseNumber {
  width: 100%;

  input {
    width: 207px;
    border: 1px solid #000;
    font-size: 20px;
    font-weight: bold;
  }

  p {
    font-size: 12px;
    margin: 0;
    width: fit-content;
  }
}

.headerRight {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
