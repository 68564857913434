@import '~config/variables.scss';

.select {
  &:global(.ant-select-disabled.ant-select-multiple .ant-select-selection-item),
  &:global(.ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector),
  :global(.ant-select-selection-placeholder) {
    color: $transparent-black;
  }

  :disabled {
    color: $black;
  }
}

.loader {
  outline: 1px solid blue;
  background-color: #fff;
}
