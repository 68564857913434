$pastelRed: #fd7f6f;

.page {
  width: 8.5in;
  height: 11in;
}

.content {
  padding-left: 25px;
  padding-right: 15px;
  width: 100%;
}

.contactsRow {
  max-height: 200px;
  height: 200px;
  box-sizing: border-box;
  background-color: #f0f1f9;
}

.resultsSection {
  max-height: 465px;
  height: 465px;
  box-sizing: border-box;
}

@media print {
  .page {
    height: 100vh;
    width: 100vw;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
}
