@import '~config/variables.scss';

.container {
  max-width: 150px;
}

.contentWrapper {
  background-color: $pale-gray;
  border-radius: 3px;
  padding: 3px;
  word-wrap: break-word;
  word-break: break-word;
  max-width: inherit;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.block{
  display: block;
  width: fit-content;
}