.historySection {
  padding: 10px;
  border: 1px solid #000;

  &Title {
    display: flex;
    gap: 5px;
    div {
      width: fit-content;
    }

    p {
      margin: 0;
      line-height: 1;
    }
  }

  .info {
    display: flex;
    gap: 4px;
  }

  .historyInputs {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;

    div {
      width: 100%;
    }
  }

  .historyInput {
    input {
        font-size: 12px;
        line-height: 1;
        height: 14px;
        padding: 0;
    }
  }
}

.checkbox {
  gap: 5px;
}
