@import '~config/variables.scss';

.day {
  width: 26px;
  height: 26px;
  text-align: center;
  vertical-align: middle;
  line-height: 26px;
  background-color: transparent;
  transition: all 0.3s;
  border-radius: 2px;
  &:hover {
    background-color: rgba($color: $black, $alpha: 0.1);
  }
  &.selected {
    background-color: rgba($color: $light-blue, $alpha: 0.8);
    &:hover {
      background-color: $light-blue;
    }
  }
  &.today {
    border: 1px solid rgba($color: $light-blue, $alpha: 0.8);
  }
}
