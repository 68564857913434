/*COLORS */
$pastelRed: #fd7f6f;
$pastelBlue: #7eb0d5;
$pastelGreen: #b2e061;
$pastelPurple: #bd7ebe;
$pastelOrange: #ffb55a;
$pastelYellow: #ffee65;
$salad: #52c41a;
$red: #eb2f45;
$black: #000000;
$transparent-black: rgba(0, 0, 0, 0.7);
$half-black: rgba(0, 0, 0, 0.5);
$light-blue: #1890ff;
$blue: #0d5292;
$dark-blue: #041527;
$white: #ffffff;
$dirty-white: #d9d9d9;
$pale-gray: #f5f5f5;
$gray: #919295;
$light-gray: #ababab;
$light-black: #333333;
$black-orange: #ff4f00;

/*SCREEN SIZE*/

$sm: 575px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1600px;
