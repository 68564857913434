.table {
  border-collapse: collapse;
  line-height: 80%;
}

.tableName {
  margin-top: 5px !important;
}

.cell {
  border: 1pt solid;
}

.cellWide {
  width: 96pt;
}

.cell-wide-alt {
  width: 97pt;
}

.cell-small {
  width: 13pt;
}

.cell-medium {
  width: 20pt;
}

.cell-large {
  width: 63pt;
}

.cellSmallAlt {
  width: 14pt;
}

.cellMediumAlt {
  width: 19pt;
}

.cellLargeAlt {
  width: 64pt;
}

.cellCenter {
  text-align: center;
}

.cellLeft {
  text-align: left;
}

.paddingSmall {
  padding-left: 1pt;
}

.paddingMedium {
  padding-left: 2pt;
}

.lineHeightSmall {
  line-height: 7pt;
}

.lineHeightTiny {
  line-height: 6pt;
}

.textIndent-none {
  text-indent: 0pt;
}

.rowFull {
  width: 100%;
}

.importantTableRow {
  height: 75pt;
}

.importantTableCell {
  width: 193pt;
  border: 1pt solid;
  padding: 0;
  padding-left: 2pt;
}

.paddingTop7 {
  padding-top: 7pt;
}

.paddingTop4 {
  padding-top: 4pt !important;
}

.textLeft {
  text-align: left;
}

.textRegularSmall {
  font-size: 7pt; 
}

.indented {
  text-indent: 0pt;
}

.lineHeight8 {
  line-height: 7pt;
}

.lineHeight94 {
  line-height: 80%;
}

.underline {
  text-decoration: underline;
}

.historyTableRow {
  height: 20pt;
}

.historyTableCell {
  width: 193pt;
  border: 1pt solid;
  padding: 0;
  padding-left: 2pt;
}

.emptyRow {
  height: 13pt;
}

.paddingTop4 {
  padding-top: 4pt;
}

.textLeft {
  text-align: left;
}

.textRegularSmall {
  font-size: 7pt;
}

.underline {
  text-decoration: underline;
}

.locationTableRow {
  height: 100pt;
}

.noteTextBold {
  font-size: 7pt;
  font-weight: bold;
  line-height: 7pt;
}

.noteTextRegular {
  font-size: 7pt;
  line-height: 80%;
}

.centerText {
  text-align: center;
}

.paddingRight2 {
  padding-right: 2pt;
}

