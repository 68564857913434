.animalIdentification {
  padding: 5px;
  border: 1px solid #000;
  margin: 5px 0;

  .sectionTitle {
    display: flex;
    align-items: center;

    div {
        width: fit-content;
    }

    p {
        font-size: 12px;
        margin: 0;
    }
  }

  .sectionHeader {
    display: flex;
    gap: 15px;
  }

  table {
    width: 100%;

    thead {
        font-size: 12px;
    }

    tbody {
        font-weight: bold;
        font-family: sans-serif;
    }

    td {
        text-align: center;
        border: 1px solid #000;
    }
  }
}
