.table {
  border-collapse: collapse;
  width: 100%;
}

.tableName {
  margin-top: 6px !important;
  margin-bottom: 3px !important;
}


.cell {
  border: 1px solid black;
  padding: 2px;
}

.header {
  text-align: left;
  padding-left: 2pt;
}

.item {
  text-align: left;
  line-height: 8pt;
}

.list {
  text-align: center;
}
