.optionLabel {
  font-weight: 500;
}

.comment {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.abbreviation {
  border-bottom: none !important;
  text-decoration: none !important;
}

