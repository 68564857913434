@import '~config/variables.scss';

.avatarContainer {
  display: flex;
  justify-content: center;
  background-color: $dirty-white;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  :global(.ant-image) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  :global(.ant-image-img) {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}
