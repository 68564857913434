.form {
    font-family: serif;
}

.footerText {
    font-size: 12px;
    text-align: center;
    margin-top: 10px;
    padding: 0 20px;
    line-height: 1;
}