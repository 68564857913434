.title {
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  margin: 6px 0 0 0;
}

.contactFormWrapper {
  border: 1px solid #000;

  label {
    font-size: 12px;
  }
}

.contactFormTopSection {
  border-bottom: 1px solid #000;

  div {
    padding: 1px 2px;
  }

  label {
    line-height: 1;
  }

  .contactFormTopSectionRight {
    border-right: 1px solid #000;
    font-weight: bold;
  }
}

.contactFormMidSection {
  .contactFormMidSectionLeft {
    border-right: 1px solid #000;
    padding: 1px 2px;
  }

  .boldLabel {
    label {
      font-weight: bold;
    }
  }

  .contactFormMidSectionRight {
    padding: 1px 3px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.contactFormBottomSection {
  border-top: 1px solid #000;
  padding: 1px 3px;

  .checkboxes {
    font-size: 12px;
    line-height: 1;
  }

  :global(.checkbox) {
    font-weight: bold;
    gap: 2px;
    label {
      font-size: 13px;
    }
  }

  .contactFormBottomSectionRight {
    line-height: 1;

    input {
      max-width: 110px;
    }
  }
}
