.title {
  font-size: 18px;
  font-weight: 600;
  font-size: 22px;
  text-align: center;
  margin-bottom: 10px;
}

.donorSelect {
  max-width: 210px;
}

.preWrap {
  white-space: pre-wrap;
}
