@import '~config/variables.scss';

.input {
  :global(.ant-input[disabled]),
  &::placeholder {
    color: $transparent-black;
  }
  &:disabled {
    color: $black;
    &:hover {
      border-color: $gray;
    }
  }
}
