@import '~config/variables.scss';

.listItem {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background 0.3s;
  color: $light-blue;

  &:hover {
    background: $pale-gray;
  }

  &:hover .delete {
    opacity: 1;
  }
}

.fileName {
  display: flex;
  display: flex;
  align-items: center;
  gap: 4px;
}

.delete {
  transition: opacity 0.3s;
  opacity: 0;
}
