@import '~config/variables.scss';

.radioGroup {
  margin: 25px 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-weight: bold;
  margin-bottom: 0;
}
