@import '~config/variables.scss';

.modal {
  max-width: 500px;
  min-width: 50%;
}

.table {
  width: 100%;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}