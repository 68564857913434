.orderItemsContainer {
  margin-top: 20px;
}

.productsContainer {
  margin-top: 15px;
  :global(.ant-collapse > .ant-collapse-item > .ant-collapse-header) {
    padding: 5px 0;
  }
}
