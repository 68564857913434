.table {
  width: 100%;
  border-collapse: collapse;
}

.checkboxCell {
  width: 1rem;
  text-align: center;
  border: 1px solid black;
}

.codeCell {
  width: 1.75rem;
  text-align: center;
  border: 1px solid black;
}

.descriptionCell {
  border: 1px solid black;
}

.emptyCell {
  width: 3rem;
  border: 1px solid black;
}

.list {
  list-style-type: none;
}
