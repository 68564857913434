.columnTitle {
  font-weight: bold;
  text-align: center;
  line-height: 1;
}

.columnSectionTitleWrapper {
  display: flex;
  justify-content: center;
  flex: 1 1 auto;
}

.columnSectionTitle {
  text-align: center;
  font-weight: bold;
  text-decoration: underline;
  line-height: 1;
  margin: 0;
}

.columnSectionSubTitle {
  font-size: 9px;
  line-height: 1;
  text-align: left;
  margin-bottom: 3px;

  &:global(.sm) {
    font-size: 7px;
    line-height: 1.5;
  }
}

.checkboxWrapper {
  display: grid;
  grid-template-columns: 10px 1fr;
  gap: 10px;
  align-items: start;
  margin-bottom: 5px;
  font-size: 9px;
  width: 100%;
  line-height: 1;
  margin-right: 6px;

  input[type='checkbox'] {
    width: 100%;
    height: 100%;
    max-height: 10px;
    border-radius: 1px;
  }
}

.input {
  border: 0;
  outline: 0;
  border-bottom: 1px solid #9e9fa4;
  height: 20px;
  line-height: 1;
  box-sizing: border-box;
  font-size: 7px;
}

.inputWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 5px;

  input {
    flex: 1 1 auto;
    width: 100px;
  }
}
.subFields {
  padding-left: 20px;
}

.checkboxRows {
  display: flex;
}

.rowCheckbox {
  display: flex;
  flex-direction: row-reverse;
  gap: 4px;
}

.rowWrapper {
  margin-left: 7px;
}

.optionsLabels {
  display: flex;
  gap: 3px;
  font-size: 10px;
  font-weight: bold;

  p {
    margin: 0;
    line-height: 1;
  }
}

.optionsCheckboxWrapper {
  display: flex;
  gap: 7px;
  font-size: 9px;
  width: 100%;
  line-height: 1;
  margin-bottom: 3px;
  align-items: center;

  label {
    max-width: 120px;
  }
}

.space {
  width: 13px;
}

.optionsInputWrapper {
  input {
    max-width: 75%;
  }
}
