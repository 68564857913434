.labworkCard {
  :global(.ant-card-head) {
    padding: 0 10px;
  }
  :global(.ant-card-body) {
    padding: 10px;
  }
}

.clipIcon {
  font-size: 25px;
  margin-right: 16px;
}
