.laboratoryUseTable {
  table, th, td {
    border: 0.5px solid #8CA1D1;
    border-collapse: collapse;
    font-size: 6pt;
    padding: 0;
    font-family: Tahoma, serif;
  }

  table {
    width: 100%;
  }

  td{
    padding: 1.7px;
  }

  img {
    width: 9px;
    height: 9px;
  }

  .input {
    border: 0;
    outline: 0;
    border-bottom: 0.5px solid #000;
    background-color: inherit;
    line-height: 1.0;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 1px;
  }

  .title{
    margin-left: 5px;
    margin-top: 5px;
    font-size: 7pt;
    font-family: Tahoma, serif;
  }
}
