.content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  width: 100%;
  padding: 5% 0;
}
