.clinicSection {
  border: 0.5px solid #8CA1D1;
  padding: 5px;
  margin-top: 5px;

  .label {
    font-size: 7pt;
    font-family: Tahoma, serif;
  }

  .input {
    border: 0;
    outline: 0;
    background-color: #E8EBFB;
    height: 15px;
    width: 100%;
    font-size: 8pt;
    font-family: Calibri, serif;
    font-weight: bold;
    margin-bottom: 5px;
  }
}
