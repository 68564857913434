@import '~config/variables.scss';

.teamCollapse {
  max-width: 100%;
  &:global(.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header),
  &:global(.ant-collapse
      .ant-collapse-item-disabled
      > .ant-collapse-header
      > .arrow) {
    color: #000;
    cursor: default;
  }
  :global(.ant-collapse-content-box) {
    padding: 10px;
  }
}

.donorLink {
  transition: all 0.3s;
  &:hover {
    color: $light-blue;
  }
}
