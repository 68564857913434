.locationName {
  max-width: 160px;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.formItem {
  margin-bottom: 6px;
  height: 59px;
  padding-top: 16px;
  word-wrap: initial;

  :global(.ant-form-item-extra) {
    white-space: nowrap;
    position: absolute;
    bottom: -25%;
    z-index: 1010;
  }
}

.locationTypeInput {
  width: 27vw;
}

.popConfirm {
  min-width: 100px;

  :global(.ant-popover-inner-content) {
    display: block;
    min-width: 200px;

  }
}