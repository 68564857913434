@import '~config/variables.scss';

.copyInfoSection {
  font-size: 7pt;
  margin-top: 5px;

  .label {
    font-family: Calibri, serif;
    font-weight: bold;
  }

  .info {
    color: $black-orange;
    font-family: Tahoma, serif;
    margin: 0 10px;
    font-weight: bold;
  }
}
