.title {
  font-family: Lucida Sans, serif;
  font-size: 10pt;
  font-weight: bold;
  color: rgba(57, 111, 182, 0);
  background-color: #f0f1f9;
  padding: 0 10px;
}

.textArea {
  background-color: #e8ebfb;
  height: 100px;
  font-size: 8pt;
  font-family: Calibri, serif;
  font-weight: bold;
}
