@import '~config/variables.scss';

.container {
  width: 3in;
  height: 3in;
  padding: 9px 0 13px;
  font-size: 8px;
  font-family: 'RobotoMedium';
  letter-spacing: 0.1px;
  page-break-after: always;

  &.large {
    font-size: 11px;
    width: 4in;
    height: 4in;
    padding: 12px 0 17px;

    .logo {
      max-width: 162px;
      margin-left: -13px;
    }

    .content {
      padding: 0 16px;
    }

    .bloodTypeContainer {
      font-size: 13px;
    }

    .productType {
      font-size: 12px;
    }

    .recommendationsTitle {
      font-size: 12px;
    }

    .recommendationsList {
      font-size: 14px;
      line-height: 18px;
      li {
        &:before {
          margin-right: 5px;
        }
      }
    }

    .usage {
      color: $blue;
      font-weight: 600;
      padding: 0 16px;
    }

    .footer {
      font-size: 10px;
      line-height: 10px;
      padding: 5px 16px 10px;
    }
  }
}

.logo {
  margin-left: -10px;
  max-width: 121px;
}

.content {
  padding: 0 12px;
}

.bloodTypeContainer {
  font-size: 10px;
  height: 100%;
}

.bold {
  font-weight: 600;
}

.productType {
  font-size: 9px;
  font-weight: 700;
}

.recommendationsTitle {
  font-weight: 700;
  font-size: 9px;
  color: $blue;
  border-bottom: 1px solid $blue;
}

.recommendationsList {
  font-size: 10px;
  line-height: 13px;
  list-style: none;
  padding: 0;
  font-family: 'BenchNineRegular';
  li {
    margin-left: 0;
    &::marker {
      content: '';
      display: none;
    }
    &:before {
      content: '';
      display: inline-block;
      border: 1px solid $black;
      border-radius: 3px;
      background-color: $black;
      width: 6px;
      height: 6px;
      margin-right: 4px;
    }
  }
}

.usage {
  color: $blue;
  font-weight: 600;
  padding: 0 12px;
}

.footer {
  background-color: #0d5292;
  color: $white;
  font-size: 8px;
  line-height: 8px;
  padding: 3px 12px 7px;
}

.species {
  display: inline-block;
  &::first-letter {
    text-transform: capitalize;
  }
}

.donorName {
  margin-top: 4cm;
  font-size: 14px;
  margin-left: 12px;
}
