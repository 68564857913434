@import '~config/variables.scss';

.dayColumn {
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    background-color: $dirty-white;
  }
}

.row {
  break-inside: avoid;
  .dayColumn {
    padding: 6px;
  }
}
