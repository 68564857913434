@import '~config/variables.scss';

.modal {
  max-width: 600px;
}

.error {
  font-size: 12px;
}

.successIcon,
.errorIcon {
  font-size: 24px;
  color: $salad;
}

.errorIcon {
  color: $red;
}

.errors {
  max-height: 200px;
  overflow-y: auto;
}

.collapse {
  &:global(.ant-collapse > .ant-collapse-item > .ant-collapse-header) {
    padding: 0 5px;
  }
}

.uploadContainer {
  height: 100%;
}

.userSelect {
  width: 100%;
}

.additionalInfoContainer {
  display: flex;
  flex-direction: column;
}

.teamSelectContainer {
  margin-bottom: 0;
}

.typeChangeMessage {
  text-align: center;
}
