@import '~config/variables.scss';

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.innerContainer {
  width: 100%;
  max-width: 600px;
}

.formContainer {
  margin: 24px 0;
  width: 100%;
  :global {
    .ant-input,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector,
    .ant-input-number {
      border-color: $gray;
      border-radius: 1px;
      .ant-select-selection-placeholder,
      ::placeholder,
      &::placeholder {
        color: $light-gray;
      }
    }
    .mask-phone-input {
      color: $light-gray;
      &:focus {
        color: $transparent-black;
      }
    }
    .ant-radio-wrapper {
      color: $transparent-black;
    }
    .ant-form-item {
      max-width: 100%;
    }
  }
}

.title {
  font-size: 32px;
  line-height: 42px;
  font-weight: 700;
  text-align: center;
  color: $red;
  text-align: center;
  margin: 24px auto;
  max-width: 300px;
}

.subtitle {
  font-size: 22px;
  line-height: 28px;
  color: $blue;
  text-align: left;
  margin: 0;
  font-weight: 700;
}

.logo {
  margin: 0 auto;
  text-align: center;
  color: white;
  max-width: 300px;
  img {
    height: auto;
    width: 100%;
  }
}
