.title {
  margin: 0;
}

.checkboxButtonWrapper {
  display: flex;
  gap: 5px;
  align-items: center;

  input {
    font-size: 10px;
  }
}

.checkboxes {
  display: flex;
  gap: 15px;
}

.labelLeft {
  display: flex;
  gap: 4px;
  align-items: center;
}

.inputWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  input {
    flex: 1 1 auto;
    width: 100px;
  }
}

.column {
  flex-direction: column;
  gap: 0;
}

.sm {
  font-size: 11px;
  gap: 7px;

  .checkboxButtonWrapper {
    gap: 2px;
  }

  label {
    font-size: 11px;
    line-height: 0;
  }

  input {
    max-width: 70px;
    height: 11px;
  }
}

.radioButtonLabel {
  line-height: 1;
}
