@import '~config/variables.scss';

.contactsSection {
    margin-top: 5px;
    margin-bottom: 5px;

  .input {
    border: 0;
    outline: 0;
    border-bottom: 1px solid #9e9fa4;
    height: 15px;
    line-height: 1;
    box-sizing: border-box;
    font-size: 18px;
  }

  .radioButton {
    margin-right: 6px;
  }

  .radioButtonLabel {
    margin-right: 5px;
    font-weight: bold;
  }

  .inputWrapper {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 2px;
    line-height: 1;
    font-size: 13px;

    input {
        flex: 1 1 auto;
        width: 100px;
    }
  }

  .petInfo {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}