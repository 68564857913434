@import '~config/variables.scss';

.select {
  :global(.ant-select-selection-placeholder) {
    color: $transparent-black;
  }

  :disabled {
    color: $black;
  }
}
