.inputNumber {
  width: 100%;
}

.yesNoFiled {
  display: flex;
  flex-direction: column;
  label {
    margin: 6px 0;
  }
}
