.icon {
  font-size: 64px;
  margin: 0 auto;
  display: block;
}

.message {
  font-size: 16px;
  line-height: 24px;
  margin: 16px 0;
  font-weight: 700;
  text-align: center;
}

.navigationButton {
  margin: 0 auto;
  display: block;
}

.resendLinkContainer {
  text-align: center;
}
