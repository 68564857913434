@import '~config/variables.scss';

.linkIcon {
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s;
  opacity: 0;
  &:hover {
    color: $light-blue;
  }
}
