@import '~config/variables.scss';

.successIcon,
.errorIcon {
  font-size: 24px;
  color: $salad;
}

.errorIcon {
  color: $red;
}
