@import '~antd/dist/antd.css';
@import '~config/variables.scss';

.content {
  padding: 0 10px 30px 10px;
  background-color: #ffffff;
  width: 100%;
}

.sider {
  :global(.ant-layout-sider-children) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @media (max-width: 991px) {
    position: absolute;
    z-index: 10;
    height: 100vh;
    top: 0;
    left: 0;
  }

  padding-bottom: 50px;
}

.menu {
  position: sticky;
  top: 0;
}

.logoutMenu {
  position: sticky;
  top: 0;
  margin-bottom: auto;
}

.logoutButton:hover {
  span {
    color: lighten($color: $black-orange, $amount: 10%);
  }
}

.logo {
  text-align: center;
  color: white;
  max-width: 300px;

  @media (max-width: 991px) {
    display: none;
  }

  img {
    height: auto;
    width: 100%;
  }
}

.title {
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  margin: 20px 0;

  @media (max-width: 991px) {
    margin: 10px 0 10px 40px;
  }
}

.header {
  background-color: #ffffff;
  height: auto;
  padding: 0;
  line-height: 28px;
}

.notificationWrapper {
  text-align: end;
  margin-right: 15px;
}

.version {
  color: $dirty-white;
  padding: 10px 20px;
  font-size: 12px;
  opacity: 0.8;
  display: flex;
  flex-direction: column;
  margin-bottom: -50px;
}

.disconnectIcon {
  font-size: 20px;
  color: $pale-gray;
  opacity: 0.3;
}

.iconContainer {
  display: flex;
  align-items: end;
}

.reloadIcon {
  font-size: 30px;
  color: $pale-gray;
  opacity: 0.6;
}

.menu {
  :global(.ant-menu-submenu-selected) {
    color: $light-blue;
  }
}

.pageTip {
  @media (max-width: 500px) {
    :global(.ant-tooltip-inner) {
      background-color: black;
    }
  }
}