@import '~config/variables.scss';

.saveButton {
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
}

.noPhoto {
  background-color: grey;
  width: 200px;
  height: 200px;
}

.photo {
  max-width: 200px;
  max-height: 200px;
}

.formItem {
  padding: 0;
  margin: 0 0 10px;
}

.selectInCell {
  text-align: left;
}

.input {
  text-align: center;
}

.environmentFormItemWrapper {
  margin-bottom: 10px;
}

.environmentFormItem {
  margin-bottom: 10px;
}

.donorSelect {
  width: 200px;
  margin-top: 10px;
}
