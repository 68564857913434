@import '~config/variables.scss';

.questionsSection {
  width: 100%;
  font-family: Calibri, serif;
  font-size: 8.5pt;
  border-bottom: 0.5px solid #336BB4;
  padding: 10px 0;
  font-weight: bold;
  color: $black-orange;
}
