.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  font-size: 10px;
  margin-top: 50px;
  padding-bottom: 0;
  margin-bottom: 0;
}

.content {
  display: flex;
  align-items: space-between;
  justify-content: space-between;
  gap: 15px;
}

.text {
  text-wrap: nowrap;
  text-align: center;
}

.number {
  text-align: right;
}
