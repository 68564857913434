@import '~config/variables.scss';

.contactsSection {
  .input {
    border: 0;
    outline: 0;
    border-bottom: 0.5px solid #9e9fa4;
    background-color: #E8EBFB;
    height: 20px;
    width: 100%;
    line-height: 1.0;
    margin-top: 9px;
    box-sizing: border-box;
    font-size: 8pt;
    font-family: Calibri, serif;
    font-weight: bold;
  }

  .radioButtonLabel {
    margin-right: 5px;
    font-family: Calibri, serif;
    font-size: 8pt;
    font-weight: bold;
  }

  .orangeText {
    color: $black-orange;
    font-family: Calibri, serif;
    font-size: 8pt;
    font-weight: bold;
  }
}

.patientId {
  margin-left: 10px;
}

.staffPetRadioButton {
  margin: 3px;
  height: 12px;
  width: 12px;
}
