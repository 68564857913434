div.page {
  padding: 25px 30px;
}

.content {
  position: relative;

  .general {
    position: absolute;
    top: 50px;
    right: -50px;
    transform: rotate(90deg);
    font-size: 18px;
    font-weight: 900;
  }
}

.footerNotes {
  font-size: 8px;
  line-height: 1;
}

.footerPage {
  font-size: 10px;
  text-align: center;
}

.footer {
  margin-top: 10px;
}
