.formWrapper {
  background-color: #fff;
  border: 1px solid #ccc;
  max-width: 800px;

  p, span {
    padding: 0;
    margin: 0;
  }
}

@media print {
  .formWrapper {
    height: 100vh;
    margin: 0 auto !important;
    padding: 10px !important;
    overflow: hidden;
  }
}

.section {
  margin-bottom: 20px;

  h2 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
}

.columnWrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.content {
  padding-top: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  font-size: 8px;
  line-height: 100%;
}

.textbox {
  border: 1pt solid ;
  display: block;
  min-height: 150.8pt;
  width: 192.8pt;
  padding: 10px;
}

.title {
  padding-top: 3pt;
  padding-left: 4pt;
  text-indent: 0;
  line-height: 8pt;
  text-align: left;
  font-size: 11pt;
}

.subtitle {
  padding-left: 4pt;
  text-indent: 0;
  line-height: 8pt;
  text-align: left;
  font-size: 8pt;
}
