.input {
  border: 0;
  outline: 0;
  border-bottom: 1px solid #9e9fa4;
  height: 20px;
  line-height: 1;
  box-sizing: border-box;
  font-size: 18px;
  max-width: 100%;
}
.inputWrapper {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 2px;
  margin-bottom: 9px;

  input {
    flex: 1 1 auto;
    width: 100px;
  }
}

.specimens {
  border: 5px solid black;
  padding: 7px;
  font-size: 12px;

  h4 {
    font-weight: bold;
    font-family: serif;
    text-align: center;
    font-size: 18px;
    text-transform: uppercase;
    margin: 0;
  }

  p {
    font-weight: bold;
    text-align: center;
    margin: 0;
  }
}
