@import '~config/variables.scss';

.signUpButton,
.signInButton {
  width: 100%;
  font-weight: 700;
  padding: 10px;
  height: auto;
  font-size: 15px;
}

.signUpButton {
  background-color: $dark-blue;
  border-color: $dark-blue;
  &:hover {
    background-color: $blue;
    border-color: $blue;
  }
}

.divider {
  &:global(.ant-divider-plain.ant-divider-with-text) {
    font-weight: 700;
    font-size: 18px;
    margin: 4px 0 0 0;
  }
  &:global(.ant-divider-horizontal.ant-divider-with-text::before),
  &:global(.ant-divider-horizontal.ant-divider-with-text::after) {
    border-top-width: 2px;
  }
}

.textCentered {
  text-align: center;
}
