.content {
  padding-left: 25px;
  padding-right: 15px;
  width: 100%;
}

.head {
  background-color: #336BB4;
  height: 60px;
  max-height: 60px;
  width: 100%;

  .textWrapper {
    margin-bottom: 15px;
  }

  .headline {
    color: #FFFFFF;
    font-family: LucidaSansRegular, serif;
    line-height: 1.0;

    &.large {
      font-size: 33px;
    }

    &.small {
      font-size: 10px;

      strong {
        font-weight: bolder;
        margin-right: 10px;
      }

      span {
        margin-left: 10px;
      }
    }
  }
}
