.formItem {
  display: flex;
  flex-direction: column;
  max-width: 440px;
  font-size: 14px;
  line-height: 22px;

  :global {
    .ant-form-item-label {
      text-align: left;
      white-space: normal;
      height: auto;
      margin-bottom: 8px;
    }

    .ant-form-item-extra {
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      min-height: 16px;
      margin-top: 8px;
    }
  }
}
