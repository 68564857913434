.inputNumber {
  width: 100%;
}

.editOwnerButton {
  max-width: 50px;
  width: 100%;
}

.lastNameContainer {
  &:global(.ant-input-group.ant-input-group-compact) {
    display: flex;
  }
}

.historyArea {
  resize: vertical;
  max-height: 150px;
}
