.sortIcon {
  rotate: 90deg;
  font-size: 25px;
}

.dropdownOverlay {
  :global(.ant-dropdown-menu) {
    padding: 10px;
  }
}

.title {
  font-size: 14px;
  font-weight: 600;
  margin: 10px 0 5px 0;
}
